import React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import IndexPageTemplate from "../components/page-templates/IndexPageTemplate"
import Layout from "../components/layout/Layout"
import SEO from "../components/helper/SEO"

const IndexPage = ({ location, data }) => {
  const { title, description, metaImage } = data.markdownRemark.frontmatter
  const metaImagePath = getSrc(metaImage)

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        pathname={location.pathname}
        image={metaImagePath}
      />
      <IndexPageTemplate pageContent={data.markdownRemark.frontmatter} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    markdownRemark(frontmatter: { contentType: { eq: "index-page" } }) {
      frontmatter {
        title
        description
        metaImage {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
        hero {
          title
          subtitle         
          claim
          trailer {
            url {
              publicURL
            }
          }
        }
        features {
          title
          mainText          
        }
        appVr {
          title
          mainText
          review {
            url
            text
          }
          image {
            url {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            alt
          }
        }
        biofeedback {
          title
          mainText
          image {
            url {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            alt
          }
        }
        breathing {
          title
          mainText
          image {
            url {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            alt
          }
        }
        restoration {
          title
          mainText
          image {
            url {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            alt
          }
        }   
        vr {
          title
          mainText
          image {
            url {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            alt
          }
        }     
        science1 {
          title
          mainText
          image {
            url {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            alt
          }
          blum {
            url
            urlText
          }
          rockstroh {
            url
            urlText
          }
        }
        science2 {
          title
          mainText
          image {
            url {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            alt
          }
          citation {
            url
            urlText
          }
        }       
        storeButtonMeta {
          text
          textShort
          url
        }
      }
    }
  }
`
